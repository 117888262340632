export let CountryCodes = [
    { name: "Afghanistan", code: "93", iso: "af" },
    { name: "Albania", code: "355", iso: "al" },
    { name: "Algeria", code: "213", iso: "dz" },
    { name: "American Samoa", code: "1684", iso: "as" },
    { name: "Andorra", code: "376", iso: "ad" },
    { name: "Angola", code: "244", iso: "ao" },
    { name: "Anguilla", code: "1264", iso: "ai" },
    { name: "Antarctica", code: "672", iso: "aq" },
    { name: "Antigua and Barbuda", code: "1268", iso: "ag" },
    { name: "Argentina", code: "54", iso: "ar" },
    { name: "Armenia", code: "374", iso: "am" },
    { name: "Aruba", code: "297", iso: "aw" },
    { name: "Australia", code: "61", iso: "au" },
    { name: "Austria", code: "43", iso: "at" },
    { name: "Azerbaijan", code: "994", iso: "az" },
    { name: "Bahamas", code: "1242", iso: "bs" },
    { name: "Bahrain", code: "973", iso: "bh" },
    { name: "Bangladesh", code: "880", iso: "bd" },
    { name: "Barbados", code: "1246", iso: "bb" },
    { name: "Belarus", code: "375", iso: "by" },
    { name: "Belgium", code: "32", iso: "be" },
    { name: "Belize", code: "501", iso: "bz" },
    { name: "Benin", code: "229", iso: "bj" },
    { name: "Bermuda", code: "1441", iso: "bm" },
    { name: "Bhutan", code: "975", iso: "bt" },
    { name: "Bolivia", code: "591", iso: "bo" },
    { name: "Bosnia and Herzegovina", code: "387", iso: "ba" },
    { name: "Botswana", code: "267", iso: "bw" },
    { name: "Brazil", code: "55", iso: "br" },
    { name: "British Indian Ocean Territory", code: "246", iso: "io" },
    { name: "British Virgin Islands", code: "1284", iso: "vg" },
    { name: "Brunei", code: "673", iso: "bn" },
    { name: "Bulgaria", code: "359", iso: "bg" },
    { name: "Burkina Faso", code: "226", iso: "bf" },
    { name: "Burundi", code: "257", iso: "bi" },
    { name: "Cambodia", code: "855", iso: "kh" },
    { name: "Cameroon", code: "237", iso: "cm" },
    { name: "Canada", code: "1", iso: "ca" },
    { name: "Cape Verde", code: "238", iso: "cv" },
    { name: "Cayman Islands", code: "1345", iso: "ky" },
    { name: "Central African Republic", code: "236", iso: "cf" },
    { name: "Chad", code: "235", iso: "td" },
    { name: "Chile", code: "56", iso: "cl" },
    { name: "China", code: "86", iso: "cn" },
    { name: "Christmas Island", code: "61", iso: "cx" },
    { name: "Cocos Islands", code: "61", iso: "cc" },
    { name: "Colombia", code: "57", iso: "co" },
    { name: "Comoros", code: "269", iso: "km" },
    { name: "Cook Islands", code: "682", iso: "ck" },
    { name: "Costa Rica", code: "506", iso: "cr" },
    { name: "Croatia", code: "385", iso: "hr" },
    { name: "Cuba", code: "53", iso: "cu" },
    { name: "Curacao", code: "599", iso: "cw" },
    { name: "Cyprus", code: "357", iso: "cy" },
    { name: "Czech Republic", code: "420", iso: "cz" },
    { name: "Democratic Republic of the Congo", code: "243", iso: "cd" },
    { name: "Denmark", code: "45", iso: "dk" },
    { name: "Djibouti", code: "253", iso: "dj" },
    { name: "Dominica", code: "1767", iso: "dm" },
    { name: "Dominican Republic", code: "1849", iso: "do" },
    { name: "East Timor", code: "670", iso: "tl" },
    { name: "Ecuador", code: "593", iso: "ec" },
    { name: "Egypt", code: "20", iso: "eg" },
    { name: "El Salvador", code: "503", iso: "sv" },
    { name: "Equatorial Guinea", code: "240", iso: "gq" },
    { name: "Eritrea", code: "291", iso: "er" },
    { name: "Estonia", code: "372", iso: "ee" },
    { name: "Ethiopia", code: "251", iso: "et" },
    { name: "Falkland Islands", code: "500", iso: "fk" },
    { name: "Faroe Islands", code: "298", iso: "fo" },
    { name: "Fiji", code: "679", iso: "fj" },
    { name: "Finland", code: "358", iso: "fi" },
    { name: "France", code: "33", iso: "fr" },
    { name: "French Polynesia", code: "689", iso: "pf" },
    { name: "Gabon", code: "241", iso: "ga" },
    { name: "Gambia", code: "220", iso: "gm" },
    { name: "Georgia", code: "995", iso: "ge" },
    { name: "Germany", code: "49", iso: "de" },
    { name: "Ghana", code: "233", iso: "gh" },
    { name: "Gibraltar", code: "350", iso: "gi" },
    { name: "Greece", code: "30", iso: "gr" },
    { name: "Greenland", code: "299", iso: "gl" },
    { name: "Grenada", code: "1473", iso: "gd" },
    { name: "Guam", code: "1671", iso: "gu" },
    { name: "Guatemala", code: "502", iso: "gt" },
    { name: "Guernsey", code:"441481", iso: "gg" },
    { name: "Guinea", code: "224", iso: "gn" },
    { name: "Guinea-Bissau", code: "245", iso: "gw" },
    { name: "Guyana", code: "592", iso: "gy" },
    { name: "Haiti", code: "509", iso: "ht" },
    { name: "Honduras", code: "504", iso: "hn" },
    { name: "Hong Kong", code: "852", iso: "hk" },
    { name: "Hungary", code: "36", iso: "hu" },
    { name: "Iceland", code: "354", iso: "is" },
    { name: "India", code: "91", iso: "in" },
    { name: "Indonesia", code: "62", iso: "id" },
    { name: "Iran", code: "98", iso: "ir" },
    { name: "Iraq", code: "964", iso: "iq" },
    { name: "Ireland", code: "353", iso: "ie" },
    { name: "Isle of Man", code: "441624", iso: "im" },
    { name: "Israel", code: "972", iso: "il" },
    { name: "Italy", code: "39", iso: "it" },
    { name: "Ivory Coast", code: "225", iso: "ci" },
    { name: "Jamaica", code: "1876", iso: "jm" },
    { name: "Japan", code: "81", iso: "jp" },
    { name: "Jersey", code: "441534", iso: "je" },
    { name: "Jordan", code: "962", iso: "jo" },
    { name: "Kazakhstan", code: "7", iso: "kz" },
    { name: "Kenya", code: "254", iso: "ke" },
    { name: "Kiribati", code: "686", iso: "ki" },
    { name: "Kosovo", code: "383", iso: "xk" },
    { name: "Kuwait", code: "965", iso: "kw" },
    { name: "Kyrgyzstan", code: "996", iso: "kg" },
    { name: "Laos", code: "856", iso: "la" },
    { name: "Latvia", code: "371", iso: "lv" },
    { name: "Lebanon", code: "961", iso: "lb" },
    { name: "Lesotho", code: "266", iso: "ls" },
    { name: "Liberia", code: "231", iso: "lr" },
    { name: "Libya", code: "218", iso: "ly" },
    { name: "Liechtenstein", code: "423", iso: "li" },
    { name: "Lithuania", code: "370", iso: "lt" },
    { name: "Luxembourg", code: "352", iso: "lu" },
    { name: "Macau", code: "853", iso: "mo" },
    { name: "Macedonia", code: "389", iso: "mk" },
    { name: "Madagascar", code: "261", iso: "mg" },
    { name: "Malawi", code: "265", iso: "mw" },
    { name: "Malaysia", code: "60", iso: "my" },
    { name: "Maldives", code: "960", iso: "mv" },
    { name: "Mali", code: "223", iso: "ml" },
    { name: "Malta", code: "356", iso: "mt" },
    { name: "Marshall Islands", code: "692", iso: "mh" },
    { name: "Mauritania", code: "222", iso: "mr" },
    { name: "Mauritius", code: "230", iso: "mu" },
    { name: "Mayotte", code: "262", iso: "yt" },
    { name: "Mexico", code: "52", iso: "mx" },
    { name: "Micronesia", code: "691", iso: "fm" },
    { name: "Moldova", code: "373", iso: "md" },
    { name: "Monaco", code: "377", iso: "mc" },
    { name: "Mongolia", code: "976", iso: "mn" },
    { name: "Montenegro", code: "382", iso: "me" },
    { name: "Montserrat", code: "1664", iso: "ms" },
    { name: "Morocco", code: "212", iso: "ma" },
    { name: "Mozambique", code: "258", iso: "mz" },
    { name: "Myanmar", code: "95", iso: "mm" },
    { name: "Namibia", code: "264", iso: "na" },
    { name: "Nauru", code: "674", iso: "nr" },
    { name: "Nepal", code: "977", iso: "np" },
    { name: "Netherlands", code: "31", iso: "nl" },
    { name: "Netherlands Antilles", code: "599", iso: "an" },
    { name: "New Caledonia", code: "687", iso: "nc" },
    { name: "New Zealand", code: "64", iso: "nz" },
    { name: "Nicaragua", code: "505", iso: "ni" },
    { name: "Niger", code: "227", iso: "ne" },
    { name: "Nigeria", code: "234", iso: "ng" },
    { name: "Niue", code: "683", iso: "nu" },
    { name: "North Korea", code: "850", iso: "kp" },
    { name: "Northern Mariana Islands", code: "1670", iso: "mp" },
    { name: "Norway", code: "47", iso: "no" },
    { name: "Oman", code: "968", iso: "om" },
    { name: "Pakistan", code: "92", iso: "pk" },
    { name: "Palau", code: "680", iso: "pw" },
    { name: "Palestine", code: "970", iso: "ps" },
    { name: "Panama", code: "507", iso: "pa" },
    { name: "Papua New Guinea", code: "675", iso: "pg" },
    { name: "Paraguay", code: "595", iso: "py" },
    { name: "Peru", code: "51", iso: "pe" },
    { name: "Philippines", code: "63", iso: "ph" },
    { name: "Pitcairn", code: "64", iso: "pn" },
    { name: "Poland", code: "48", iso: "pl" },
    { name: "Portugal", code: "351", iso: "pt" },
    { name: "Puerto Rico", code: "1787", iso: "pr" },
    { name: "Qatar", code: "974", iso: "qa" },
    { name: "Republic of the Congo", code: "242", iso: "cg" },
    { name: "Reunion", code: "262", iso: "re" },
    { name: "Romania", code: "40", iso: "ro" },
    { name: "Russia", code: "7", iso: "ru" },
    { name: "Rwanda", code: "250", iso: "rw" },
    { name: "Saint Barthelemy", code: "590", iso: "bl" },
    { name: "Saint Helena", code: "290", iso: "sh" },
    { name: "Saint Kitts and Nevis", code: "1869", iso: "kn" },
    { name: "Saint Lucia", code: "1758", iso: "lc" },
    { name: "Saint Martin", code: "590", iso: "mf" },
    { name: "Saint Pierre and Miquelon", code: "508", iso: "pm" },
    { name: "Saint Vincent and the Grenadines", code: "1784", iso: "vc" },
    { name: "Samoa", code: "685", iso: "ws" },
    { name: "San Marino", code: "378", iso: "sm" },
    { name: "Sao Tome and Principe", code: "239", iso: "st" },
    { name: "Saudi Arabia", code: "966", iso: "sa" },
    { name: "Senegal", code: "221", iso: "sn" },
    { name: "Serbia", code: "381", iso: "rs" },
    { name: "Seychelles", code: "248", iso: "sc" },
    { name: "Sierra Leone", code: "232", iso: "sl" },
    { name: "Singapore", code: "65", iso: "sg" },
    { name: "Sint Maarten", code: "1721", iso: "sx" },
    { name: "Slovakia", code: "421", iso: "sk" },
    { name: "Slovenia", code: "386", iso: "si" },
    { name: "Solomon Islands", code: "677", iso: "sb" },
    { name: "Somalia", code: "252", iso: "so" },
    { name: "South Africa", code: "27", iso: "za" },
    { name: "South Korea", code: "82", iso: "kr" },
    { name: "South Sudan", code: "211", iso: "ss" },
    { name: "Spain", code: "34", iso: "es" },
    { name: "Sri Lanka", code: "94", iso: "lk" },
    { name: "Sudan", code: "249", iso: "sd" },
    { name: "Suriname", code: "597", iso: "sr" },
    { name: "Svalbard and Jan Mayen", code: "47", iso: "sj" },
    { name: "Swaziland", code: "268", iso: "sz" },
    { name: "Sweden", code: "46", iso: "se" },
    { name: "Switzerland", code: "41", iso: "ch" },
    { name: "Syria", code: "963", iso: "sy" },
    { name: "Taiwan", code: "886", iso: "tw" },
    { name: "Tajikistan", code: "992", iso: "tj" },
    { name: "Tanzania", code: "255", iso: "tz" },
    { name: "Thailand", code: "66", iso: "th" },
    { name: "Togo", code: "228", iso: "tg" },
    { name: "Tokelau", code: "690", iso: "tk" },
    { name: "Tonga", code: "676", iso: "to" },
    { name: "Trinidad and Tobago", code: "1868", iso: "tt" },
    { name: "Tunisia", code: "216", iso: "tn" },
    { name: "Turkey", code: "90", iso: "tr" },
    { name: "Turkmenistan", code: "993", iso: "tm" },
    { name: "Turks and Caicos Islands", code: "1649", iso: "tc" },
    { name: "Tuvalu", code: "688", iso: "tv" },
    { name: "U.S. Virgin Islands", code: "1340", iso: "vi" },
    { name: "Uganda", code: "256", iso: "ug" },
    { name: "Ukraine", code: "380", iso: "ua", locale: 'uk' },
    { name: "United Arab Emirates", code: "971", iso: "ae" },
    { name: "United Kingdom", code: "44", iso: "gb" },
    { name: "United States", code: "1", iso: "us" },
    { name: "Uruguay", code: "598", iso: "uy" },
    { name: "Uzbekistan", code: "998", iso: "uz" },
    { name: "Vanuatu", code: "678", iso: "vu" },
    { name: "Vatican", code: "379", iso: "va" },
    { name: "Venezuela", code: "58", iso: "ve" },
    { name: "Vietnam", code: "84", iso: "vn" },
    { name: "Wallis and Futuna", code: "681", iso: "wf" },
    { name: "Western Sahara", code: "212", iso: "eh" },
    { name: "Yemen", code: "967", iso: "ye" },
    { name: "Zambia", code: "260", iso: "zm" },
    { name: "Zimbabwe", code: "263", iso: "zw" }
]