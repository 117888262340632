import { activeLocale, transl } from "../useTranslation";
import { CountryCodes } from "./countries.constants";

const codes: any[] = [...CountryCodes];
const primaryValue = ref<any>(null)

const codesCollection: any = computed(() => {
    codes.sort((a, b) =>
        new Intl.Collator(activeLocale.value).compare(
            transl('Texts:' + a.country).toLowerCase(),
            transl('Texts:' + b.country).toLowerCase()
        )
    );

    //setup primary
    if (primaryValue.value) {
        return getPrimaryOrdered(codes, primaryValue.value);
    }

    return codes;
});

function matchCountryCode(text: string) {

    if (!text || !text.trim().length)
        return codesCollection.value;

    const searchDigits = text.match(/[0-9]+/);
    const matchCountry = (s: string) => new RegExp("^" + text, "gi").test(s);
    const matchCode = (s: string) => new RegExp("^" + searchDigits, "gi").test(s);

    const matchedItems = codesCollection.value.filter(
        (country: any) =>
            matchCountry(transl(`Country:${country.name}`)) ||
            matchCountry(country.name) ||
            matchCountry(country.iso) ||
            matchCode(country.code)
    );

    return matchedItems;
}

function findCountryByCode(code: any) {
    if (!code) return null;

    return codes.find(c => c.code == code);
}

function findCountryByIso(countryIso: string) {
    if (!countryIso) return null;

    return codes.find(c => c.iso == countryIso);    
}

function getPrimaryOrdered(codesColl: any[], primaryValue: any) {
    const primaryItem = codesColl.find(c => c.iso == primaryValue)
    const othersColl = codesColl.filter(c => c.iso != primaryValue);

    return [primaryItem, ...othersColl];
}

function setPrimary(value: any) {
    primaryValue.value = value;
}

export function useCountries() {

    return {
        matchCountryCode,
        findCountryByCode,
        findCountryByIso,
        setPrimary,
        codesCollection,
    }
}